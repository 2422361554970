// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { getUrlParameter } from '@finect/front-utils/getUrlParameter';
import { getURLWithQueryString } from '@finect/front-utils/getURLWithQueryString';
import { isBrowser } from '@finect/front-utils/isBrowser';
import ACTIONS from '../constants';
import { getProducts } from '../products/actions';
import { getArticlesFeed, getTagNextArticles } from '../articles/actions';
import { shuffle } from '../../utils';
import { fetchSetting } from '../settings/actions';
import { setError } from './../error/actions';
import { models } from './../../utils/models';
import type { ReviewsOrderBy } from './types';
import { aliasWithTagContent } from './../../scenes/Articles/data';

// Detalle de tag
export function setTagFetchingStatus(id: string, fetching: boolean, fetched: boolean) {
  return {
    type: ACTIONS.SET_TAG_FETCHING_STATUS,
    id,
    fetching,
    fetched
  };
}

export function setTag(tag: Object | null) {
  return {
    type: ACTIONS.SET_TAG,
    tag
  };
}

// Lista de tags
export function setTags(tags: Array<Object>) {
  return {
    type: 'SET_TAGS',
    tags
  };
}

/**
 * Obtiene una tag a partir de su identificador (ID o alias)
 *
 * @param {string} alias alias de la tag que se desea cargar
 */
export function getTag(alias: string) {
  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(setTagFetchingStatus(alias, true, true))])
      .then(() =>
        finectAPI({
          version: 'v4',
          path: `contents/tags/${alias}`,
          method: 'GET',
          onSuccess: ({ data }: { data: Object }) => Promise.resolve(dispatch(setTag(data))),
          onError: () => Promise.resolve(dispatch(setTag(null)))
        }))
      .finally(() => Promise.resolve(dispatch(setTagFetchingStatus(alias, false, true))));
  };
}

export function clearTag() {
  return {
    type: ACTIONS.CLEAR_TAG
  };
}

export function setTagContentsFetchingStatus(
  contentType: 'videos',
  entityId: string,
  fetching: boolean,
  fetched: boolean
) {
  return {
    type: ACTIONS.SET_TAG_CONTENTS_FETCHING_STATUS,
    contentType,
    entityId,
    fetching,
    fetched
  };
}

// GET para obtener vídeos de una tag
// path: `contents/articles?filter=tag+eq+${tagId}+and+type+eq+'video'&orderby=-revision/updated`,

// ----->
export function setArticle(article: Object) {
  return {
    type: 'SET_ARTICLE',
    article
  };
}

export function clearArticle() {
  return {
    type: 'CLEAR_ARTICLE'
  };
}

export function setArticleNotFound() {
  return {
    type: 'SET_ARTICLE_NOT_FOUND'
  };
}

export function setArticleComments(comments: Array<Object>, paging: Object) {
  return {
    type: 'SET_ARTICLE_COMMENTS',
    comments,
    paging
  };
}

export function setArticleUserComment(comment: Object) {
  return {
    type: 'SET_ARTICLE_USER_COMMENT',
    comment
  };
}

export function setArticleCommentsFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_ARTICLE_COMMENTS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setRecommendedArticles(articles: Object) {
  return {
    type: 'SET_RECOMMENDED_ARTICLES',
    articles
  };
}

export function setRecommendedArticlesFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_RECOMMENDED_ARTICLES_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setRelatedQuestions(questions: Object) {
  return {
    type: 'SET_RELATED_QUESTIONS',
    questions
  };
}

export function setRelatedQuestionsFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_RELATED_QUESTIONS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setRelatedProducts(products: Object) {
  return {
    type: 'SET_RELATED_PRODUCTS',
    products
  };
}

export function setRelatedProductsFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_RELATED_PRODUCTS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setRelatedGroupArticles(articles: Object) {
  return {
    type: 'SET_RELATED_GROUP_ARTICLES',
    articles
  };
}

export function setRelatedGroupArticlesFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_RELATED_GROUP_ARTICLES_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setGroups(groups: Array<Object>, paging: Object) {
  return {
    type: 'SET_GROUPS',
    groups,
    paging
  };
}

export function setGroupsFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_GROUPS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setGroupsWithFilter(groups: Array<Object>, paging: Object) {
  return {
    type: 'SET_GROUPS_WITH_FILTERS',
    groups,
    paging
  };
}

export function setGroupsFilters(filters: string) {
  return {
    type: 'SET_GROUPS_FILTERS',
    filters
  };
}

export function clearGroups() {
  return {
    type: 'CLEAR_GROUPS'
  };
}

export function setHomePodcasts(homePodcasts: Array<Object>, paging: Object, replace: boolean) {
  return {
    type: 'SET_HOME_PODCASTS',
    homePodcasts,
    paging,
    replace
  };
}

export function setHomePodcastsFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_HOME_PODCASTS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setHomeVideos(homeVideos: Array<Object>, paging: Object, replace: boolean) {
  return {
    type: 'SET_HOME_VIDEOS',
    homeVideos,
    paging,
    replace
  };
}

export function setHomeVideosFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_HOME_VIDEOS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setVideos(videos: Array<Object>, paging: Object, replace: boolean) {
  return {
    type: 'SET_VIDEOS',
    videos,
    paging,
    replace
  };
}

export function clearMultimedia() {
  return {
    type: 'CLEAR_VIDEOS'
  };
}

export function setVideosFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_VIDEOS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setVideosWithFilter(videos: Array<Object>, paging: Object) {
  return {
    type: 'SET_VIDEOS_WITH_FILTERS',
    videos,
    paging
  };
}

export function setMultimediaFilters(filters: string) {
  return {
    type: 'SET_VIDEOS_FILTERS',
    filters
  };
}

export function postUserComment(articleId: string, comment: string, image: string, author: Object) {
  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: `contents/articles/${articleId}/comments`,
      method: 'POST',
      headers: {
        'x-referrer': `${window.location.href}#comentarios`
      },
      body: {
        body: {
          text: comment
        },
        image
      },
      onSuccess: ({ code, data }: { code: number, data: Object }) => {
        code && code === 302
          ? (window.location.href = data.location)
          : dispatch(setArticleUserComment(models.comment(data, [author])));
      },
      onError: () => {
        dispatch(setError({ code: 404, message: 'No se pudo realizar el comentario' }));
      }
    });
}

export function getArticleComments(article: string, limit: number) {
  return (dispatch: Dispatch) => {
    dispatch(setArticleCommentsFetchingStatus(true, true));

    return finectAPI({
      version: 'v4',
      path: `contents/articles/${article}/comments?limit=${limit || 5}`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Object, paging: Object }) => {
        dispatch(setArticleCommentsFetchingStatus(false, true));
        dispatch(setArticleComments(models.articleComments(data.comments, data.authors), paging));
      },

      onError: () => {
        dispatch(setArticleCommentsFetchingStatus(false, true));
        dispatch(setError({ code: 404, message: 'Artículo no encontrado' }));
      }
    });
  };
}

export function getArticleCommentsPrevPage(article: string, limit: number) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(setArticleCommentsFetchingStatus(true, true));

    const links = getState().content.articleComments.paging.links;
    const prev = links.find(link => link.rel === 'prev');

    if (prev && prev.href) {
      const offset = getUrlParameter('offset', prev.href);

      return finectAPI({
        version: 'v4',
        path: `contents/articles/${article}/comments?limit=${limit || 5}&offset=${offset}`,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) => {
          dispatch(setArticleCommentsFetchingStatus(false, true));
          dispatch(setArticleComments(models.articleComments(data.comments, data.authors), paging));
        },

        onError: () => {
          dispatch(setArticleCommentsFetchingStatus(false, true));
          dispatch(setError({ code: 404, message: 'Error al cargar los comentarios' }));
        }
      });
    }
  };
}

export function getRecommendedArticles(cookies: string = 'include', limit: number) {
  return (dispatch: Dispatch) =>
    Promise.resolve(dispatch(setRecommendedArticlesFetchingStatus(true, true))).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/articles?filter=tag+eq+34018&orderby=evergreen&limit=${limit}`,
        credentials: cookies,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) =>
          dispatch(setRecommendedArticles(models.articles(shuffle(data.articles).slice(0, 5), data.authors))),
        onError: () => dispatch(setRecommendedArticles([]))
      }));
}

export function getRelatedGroupArticles(cookies: string = 'include', author: string, limit: number = 6) {
  return (dispatch: Dispatch) =>
    Promise.resolve(dispatch(setRelatedGroupArticlesFetchingStatus(true, true))).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/profiles/${author}/articles?limit=${limit}`,
        credentials: cookies,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) =>
          dispatch(setRelatedGroupArticles(models.articles(data.articles, data.authors))),
        onError: () => dispatch(setRelatedGroupArticles([]))
      }));
}

export function getRelatedQuestions(article: Object, cookies: string = 'include') {
  return (dispatch: Dispatch) =>
    Promise.resolve(dispatch(setRelatedQuestionsFetchingStatus(true, true))).then(() => {
      const tags = (article.socialStats && article.socialStats.tags.map(tag => `tag+eq+${tag.id}`)) || [];

      return finectAPI({
        version: 'v4',
        path: `contents/questions?limit=5${tags.length ? `&filter=${tags.join('+or+')}` : ''}`,
        credentials: cookies,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) =>
          dispatch(setRelatedQuestions(models.articles(data.questions, data.authors))),

        onError: () => dispatch(setRelatedQuestions([]))
      });
    });
}

export function getRelatedProducts(article: Object, cookies: string = 'include') {
  return (dispatch: Dispatch) => {
    dispatch(setArticleCommentsFetchingStatus(true, true));

    return finectAPI({
      version: 'v4',
      path: `contents/articles/${article.id}/products?expand=stats/performance`,
      credentials: cookies,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => {
        dispatch(setRelatedProducts(data));
        dispatch(setRelatedProductsFetchingStatus(false, true));
      },

      onError: () => {
        dispatch(setRelatedProductsFetchingStatus(false, true));
        dispatch(setError({ code: 404, message: 'Productos no encontrados' }));
      }
    });
  };
}

/**
 * Acción que carga los grupos para /grupos
 *
 */
export function getGroups(limit: number = 24, page: number = 1, tag?: number, cookie?: string) {
  const offset = limit * (page - 1);

  return (dispatch: Dispatch, getState: Function) =>
    Promise.resolve(dispatch(setGroupsFetchingStatus(true, true))).then(() => {
      const order = getState().content.groups.request.filters.order;

      return finectAPI({
        version: 'v4',
        path: `contents/profiles/organizations?filter=features/blog+eq+true&limit=${limit || 24}${
          order ? `&orderby=${order}` : ''
        }&offset=${offset}`,
        credentials: cookie,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Array<Object>, paging: Object }) =>
          Promise.all([dispatch(setGroupsFetchingStatus(false, true)), dispatch(setGroups(data, paging))]),
        onError: () =>
          Promise.all([
            dispatch(setGroupsFetchingStatus(false, true)),
            dispatch(setError({ code: 404, message: 'Error al cargar los grupos' }))
          ])
      });
    });
}

export function getGroupsNextPage(limit: number) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(setGroupsFetchingStatus(true, true));

    const links = getState().content.groups.paging.links;

    const next = links.find(link => link.rel === 'next');
    if (next && next.href) {
      const offset = getUrlParameter('offset', next.href);
      const order = getUrlParameter('orderby', next.href);
      return finectAPI({
        version: 'v4',
        path: `contents/profiles/organizations?filter=features/blog+eq+true&limit=${limit || 24}${
          order ? `&orderby=${order}` : ''
        }&offset=${offset}`,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Array<Object>, paging: Object }) => {
          dispatch(setGroupsFetchingStatus(false, true));
          dispatch(setGroups(data, paging));
        },

        onError: () => {
          dispatch(setGroupsFetchingStatus(false, true));
          dispatch(setError({ code: 404, message: 'Error al cargar los grupos' }));
        }
      });
    }
  };
}

export function getGroupsWithFilter(order: string, limit: number) {
  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: `contents/profiles/organizations?filter=features/blog+eq+true&limit=${limit || 24}${
        order ? `&orderby=${order}` : ''
      }`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Array<Object>, paging: Object }) =>
        Promise.all([dispatch(setGroupsFetchingStatus(false, true)), dispatch(setGroupsWithFilter(data, paging))]),
      onError: () =>
        Promise.all([
          dispatch(setGroupsFetchingStatus(false, true)),
          dispatch(setError({ code: 404, message: 'Error al cargar los grupos' }))
        ])
    });
}

/**
 * Acción que carga los videos para /videos
 *
 */

export function getMultimedia(type: 'video' | 'podcast' = 'video', cookies: string, limit?: number, tag?: number) {
  return (dispatch: Dispatch, getState: Function) =>
    Promise.resolve(dispatch(setVideosFetchingStatus(true, true))).then(() => {
      const filter = tag || getState().content.videos.request.filters.filter;

      const path = getURLWithQueryString(
        'contents/articles',
        {
          orderby: '-revision/created',
          filter: `type+eq+'${type}'${filter ? `+and+tag+eq+${filter}` : ''}`,
          limit: limit || 12
        },
        { encode: false }
      );

      return finectAPI({
        version: 'v4',
        method: 'GET',
        path,
        credentials: cookies,
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
          dispatch(setVideos(models.videos(data.articles, data.authors), paging, true)),
        onError: () => dispatch(setVideosFetchingStatus(false, true))
      });
    });
}

/**
 * Acción que carga los vídeos para la home de Finect.
 *
 * Son los publicados por el usuario "__Finect".
 * Estos: https://dashboard2.finect.com/contents/articles?search=&tags=&type=video&user=__Finect
 *
 */
export function getHomeVideos(cookies: string, limit?: number) {
  return (dispatch: Dispatch) =>
    Promise.resolve(dispatch(setHomeVideosFetchingStatus(true, true))).then(() => {
      const path = getURLWithQueryString(
        'contents/articles',
        {
          orderby: '-revision/updated',
          filter: `type+eq+'video'+and+tag+eq+2333`,
          limit: limit || 12
        },
        { encode: false }
      );

      return finectAPI({
        version: 'v4',
        method: 'GET',
        path,
        credentials: cookies,
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
          dispatch(setHomeVideos(models.videos(data.articles, data.authors), paging, true)),
        onError: () => dispatch(setHomeVideosFetchingStatus(false, true))
      });
    });
}

export function getHomePodcasts(cookies: string, limit?: number) {
  return (dispatch: Dispatch) =>
    Promise.resolve(dispatch(setHomePodcastsFetchingStatus(true, true))).then(() => {
      const path = getURLWithQueryString(
        'contents/articles',
        {
          orderby: '-revision/updated',
          filter: `type+eq+'podcast'+and+tag+eq+2333`,
          limit: limit || 12
        },
        { encode: false }
      );

      return finectAPI({
        version: 'v4',
        method: 'GET',
        path,
        credentials: cookies,
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
          dispatch(setHomePodcasts(models.videos(data.articles, data.authors), paging, true)),
        onError: () => dispatch(setHomePodcastsFetchingStatus(false, true))
      });
    });
}

export function getMultimediaNextPage(type: 'video' | 'podcast' = 'video', limit: number) {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch(setVideosFetchingStatus(true, true));

    const links = getState().content.videos.paging.links;
    const filter = getState().content.videos.request.filters.filter;

    const next = links.find(link => link.rel === 'next');
    if (next && next.href) {
      const offset = getUrlParameter('offset', next.href);

      return finectAPI({
        version: 'v4',
        path: `contents/articles?filter=type+eq+'${type}'${filter ? `+and+tag+eq+${filter}` : ''}&limit=${
          limit || 12
        }&orderby=-revision/updated&offset=${offset}`,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) => {
          dispatch(setVideosFetchingStatus(false, true));
          dispatch(setVideos(models.videos(data.articles, data.authors), paging, false));
        },

        onError: () => {
          dispatch(setVideosFetchingStatus(false, true));
          dispatch(setError({ code: 404, message: 'Error al cargar los Videos' }));
        }
      });
    }
  };
}

export function getMultimediaWithFilter(type: 'video' | 'podcast' = 'video', filter: string, limit: number) {
  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: `contents/articles?filter=type+eq+'${type}'${filter ? `+and+tag+eq+${filter}` : ''}&limit=${
        limit || 12
      }&orderby=-revision/updated`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
        Promise.all([
          dispatch(setVideosFetchingStatus(false, true)),
          dispatch(setVideosWithFilter(models.videos(data.articles, data.authors), paging))
        ]),
      onError: () =>
        Promise.all([
          dispatch(setVideosFetchingStatus(false, true)),
          dispatch(setError({ code: 404, message: 'Error al cargar los Videos' }))
        ])
    });
}

export function getTags() {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/tags?limit=50`,
      method: 'GET',
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setTags(data)),
      onError: () => Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los temas' }))])
    });
  };
}

export function setTagContents(contents: Array<Object>, paging: Object, add: boolean) {
  return {
    type: ACTIONS.SET_TAG_CONTENTS,
    contents,
    paging,
    add
  };
}

export function setTagLatestContents(contents: Array<Object>) {
  return {
    type: ACTIONS.SET_TAG_LATEST_CONTENTS,
    contents
  };
}

export function setAdvisersProfiles(profiles: Array<Object>) {
  return {
    type: 'SET_ADVISERS_PROFILES',
    profiles
  };
}

export function setContentsFetchingStatus(fetching: boolean) {
  return {
    type: ACTIONS.SET_TAG_CONTENTS_FETCHING_STATUS,
    fetching
  };
}

export function setTagLatestContentsFetchingStatus(fetching: boolean) {
  return {
    type: ACTIONS.SET_TAG_LATEST_CONTENTS_FETCHING_STATUS,
    fetching
  };
}

/**
 * Setea los vídeos asociados a una tag
 *
 * @param {*} tagId
 * @param {*} videos
 */
export function setTagVideos(tagId: string, videos: Array<Object>) {
  return {
    type: ACTIONS.SET_TAG_VIDEOS,
    tagId,
    videos
  };
}

/**
 * Obtiene los vídeos de una tag
 *
 * @param {*} tagId
 * @returns
 */
export function getTagVideos(tagId: string) {
  return function (dispatch: Dispatch) {
    return Promise.resolve(dispatch(setTagContentsFetchingStatus('videos', tagId, true, false))).then(() =>
      finectAPI({
        version: 'v4',
        // TODO: Ordenar por evergreen cuando se pueda editar desde el dashboard
        path: `contents/articles?filter=tag+eq+${tagId}+and+type+eq+'video'&orderby=-revision/updated`,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) => dispatch(setTagVideos(tagId, data.articles))
      }));
  };
}

const getModelsByType = (contents, authors) =>
  contents
    .map((content) => {
      if (['article', 'video', 'external-link', 'podcast'].includes(content.type)) {
        return models.article(content, authors);
      }

      if (content.type === 'question') {
        return models.question(content, authors);
      }

      return null;
    })
    .filter(item => item);

/**
 * Obtiene contenidos asociados a una tag
 *
 * En principio esta acción es la que carga contenidos ordenados
 * descendentemente en /temas/:alias
 *
 * @param {¿number?} tag Id de la tag
 * @param {*} limit Número de elementos a cargar
 */
export function getTagContents(tag: number, page: number, withEvergreen?: boolean, limit?: number, cookie?: string) {
  const query = {
    filter: `tag+eq+${tag}`,
    limit: limit || 10,
    offset: (limit || 10) * (page - 1),
    orderby: `${withEvergreen ? 'evergreen,' : ''}-revision/created`
  };

  return function (dispatch: Dispatch) {
    return Promise.resolve(dispatch(setContentsFetchingStatus(true))).then(() =>
      finectAPI({
        version: 'v4',
        path: getURLWithQueryString('contents', query, { encode: false }),
        method: 'GET',
        credentials: cookie,
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
          dispatch(setTagContents(getModelsByType(data.contents || [], data.authors || []), paging, false)),
        onError: () =>
          Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
      }));
  };
}

export function getTagContentsNextPage(tag: number, limit?: number, next: Object) {
  return function (dispatch: Dispatch) {
    return Promise.resolve(dispatch(setContentsFetchingStatus(true))).then(() => {
      if (next && next.href) {
        const offset = getUrlParameter('offset', next.href);

        const query = {
          filter: `tag+eq+${tag}`,
          limit: limit || 10,
          offset,
          orderby: '-revision/created'
        };

        return finectAPI({
          version: 'v4',
          path: getURLWithQueryString('contents', query, { encode: false }),
          method: 'GET',
          onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
            dispatch(setTagContents(getModelsByType(data.contents || [], data.authors || []), paging, true)),
          onError: () =>
            Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
        });
      }
    });
  };
}

export function getTagLatestContents(tag: number, page: number, limit?: number, cookie?: string, tagExclude?: number) {
  const query = {
    filter: `tag+eq+${tag}${tagExclude ? `+and+tag+ne+${tagExclude}` : ''}`,
    limit: limit || 10,
    offset: (limit || 10) * (page - 1),
    orderby: '-revision/created'
  };

  return function (dispatch: Dispatch) {
    return Promise.resolve(dispatch(setTagLatestContentsFetchingStatus(true))).then(() =>
      finectAPI({
        version: 'v4',
        path: getURLWithQueryString(`contents/articles`, query, { encode: false }),
        method: 'GET',
        credentials: cookie,
        onSuccess: ({ data }: { data: Object }) =>
          dispatch(setTagLatestContents(getModelsByType(data.articles || [], data.authors || []))),
        onError: () =>
          Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los últimos contenidos de la tag' }))])
      }));
  };
}

export function getAdvisersProfiles(
  filter: { tag?: number | string, subtype?: string },
  order: string = 'evergreen',
  limit: number = 10
) {
  const { tag, subtype } = filter;

  const filters = [];

  if (tag) filters.push(`tag+eq+${tag}`);
  if (subtype) filters.push(`subtype+eq+'${subtype}'`);

  const filterPath = filters.length ? `?filter=${filters.join('+and+')}` : '';
  const limitPath = limit ? `${filterPath ? '&' : '?'}limit=${limit}` : '';

  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `/contents/profiles/advisers${filterPath}${limitPath}${order ? `&orderby=${order}` : ''}`,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => dispatch(setAdvisersProfiles(data)),
      onError: () =>
        Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
    });
  };
}

/**
 * Carga contenidos asociados a una tag
 *
 * @param {Object} tag Modelo de tag
 *
 */
export function getTagMainContents(tag: Object, page?: number = 1, withEvergreen?: boolean, cookie?: string) {
  return function (dispatch: Function) {
    const promises = [
      dispatch(getTagContents(tag.id, page, withEvergreen, 30, cookie)),
      dispatch(getTagLatestContents(tag.id, page, 20, cookie)),

      dispatch(getTagVideos(tag.id)),

      // getProducts está en products
      dispatch(getProducts(`tag-${tag.alias}`, { tag: tag.id, limit: 20 })),

      // Por qué ???
      dispatch(getAdvisersProfiles({ tag: tag.id }))
    ];

    return Promise.all(promises);
  };
}

/**
 * Obtiene una tag y su contenido asociado:
 *
 * - Productos
 * - Contenido
 * - Perfiles de asesores
 */

export function getTagWithContents(tag: string, page?: number = 1, withEvergreen?: boolean, cookie?: string) {
  return function (dispatch: Function, getState: Function) {
    return dispatch(getTag(`${tag}`)).then(() => {
      const { model } = getState().content.tag;

      const promises = model ? [dispatch(getTagMainContents(model, page, withEvergreen, cookie))] : [];

      return Promise.all(promises);
    });
  };
}

export function getTagWithLatestContents(tag: string, page?: number = 1, cookie?: string, tagExclude?: number) {
  return function (dispatch: Function, getState: Function) {
    return dispatch(getTag(`${tag}`)).then(() => {
      const { model } = getState().content.tag;

      const promises = model ? [dispatch(getTagLatestContents(model.id, page, 10, cookie, tagExclude))] : [];

      return Promise.all(promises);
    });
  };
}

// Questions
export function setQuestions(id: string, request: Object, questions: Array<Object>) {
  return {
    id,
    type: 'SET_QUESTIONS',
    items: questions,
    request
  };
}

export function getProductQuestions(
  productId: string,
  offset: number = 0
  /* filter: Object */
) {
  const id = `product-id-${productId}`;

  const request = {
    offset,
    filter: { productId }
  };

  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/products/${productId}/questions`,
      method: 'GET',
      onSuccess: ({
        data
      }: {
        code: number,
        data: {
          questions: Array<Object>,
          authors: Array<Object>
        }
      }) => {
        const questions = models.questions(data.questions, data.authors);

        const promises = [dispatch(setQuestions(id, request, questions))];

        return Promise.all(promises);
      },
      onError: () => {
        const promises = [dispatch(setQuestions(id, request, []))];

        return Promise.all(promises);
      }
    });
  };
}

// REVIEWS
export function setReviews(id: string, request: Object, reviews: Array<Object>, paging: Object) {
  return {
    id,
    type: 'SET_REVIEWS',
    items: reviews,
    request,
    paging
  };
}

export function setReview(id: string, request: Object, reviews: Array<Object>) {
  return {
    id,
    type: 'SET_REVIEWS',
    items: reviews,
    request: {},
    paging: {}
  };
}

export function setReviewsFetchingStatus(id: string, fetching: boolean, fetched: boolean, request: Object) {
  return {
    type: 'SET_REVIEWS_FETCHING_STATUS',
    id,
    fetching,
    fetched,
    request
  };
}

type ProductReviewsOptions = {
  offset?: number,
  limit?: number,
  score?: 1 | 2 | 3 | 4 | 5 | null,
  orderBy?: ReviewsOrderBy,
  productId: string
};

export function getProductReviews(params: ProductReviewsOptions) {
  const { productId } = params;
  const id = `product-id-${productId}`;

  const defaultParams = {
    offset: 0,
    limit: 25,
    score: null,
    orderBy: '-revision/created'
  };

  const request = { ...defaultParams, ...params };

  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(setReviewsFetchingStatus(id, true, true, request))]).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/products/${productId}/reviews?limit=${request.limit}&offset=${request.offset}&orderby=${
          request.orderBy
        }${request.score ? `&filter=score+eq+'${request.score}'` : ''}`,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) => {
          const reviews = models.reviews(data.reviews, data.authors);
          const promises = [dispatch(setReviews(id, request, reviews, paging))];

          return Promise.all(promises);
        },
        onError: () => {
          const promises = [dispatch(setReviews(id, request, []))];

          return Promise.all(promises);
        }
      }));
  };
}

export function getProductReview(params: { reviewId: string }) {
  const { reviewId } = params;
  const id = `review-id-${reviewId}`;

  const request = { ...{}, ...params };

  return (dispatch: Dispatch) =>
    Promise.all([dispatch(setReviewsFetchingStatus(id, true, true, request))]).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/reviews/${reviewId}`,
        method: 'GET',
        onError: () => {
          const promises = [dispatch(setReview(id, request, []))];

          return Promise.all(promises);
        },
        onSuccess: ({ data }: { data: Object }) => {
          const review = models.review(data.review, data.authors);

          const promises = [dispatch(setReview(id, request, [review]))];

          return Promise.all(promises);
        }
      }).finally(() => Promise.all([dispatch(setReviewsFetchingStatus(id, false, true, request))])));
}

export function setArticles(id: string, contents: Array<Object>, paging: Object) {
  return {
    type: 'SET_ARTICLES_TAG',
    id,
    contents,
    paging
  };
}

export function setArticlesFetchingStatus(id: string, fetching: boolean) {
  return {
    type: 'SET_ARTICLES_TAG_FETCHING_STATUS',
    id,
    fetching
  };
}

/**
 * Carga los artículos listables de un escaparate
 *
 * @param {*} tag
 * @param {*} limit
 * @param {*} orderby
 * @param {*} operator operador con el que se unen las tags cuando se pasa un array
 */
export function getArticles(tag: number | Array<number>, limit?: number, orderby?: string, operator?: string = 'or') {
  return (dispatch: Dispatch) =>
    Promise.resolve(
      dispatch(setArticlesFetchingStatus(typeof tag === 'object' ? `${tag.map(t => t).join('-')}` : `${tag}`, true))
    ).then(() =>
      finectAPI({
        version: 'v4',
        path: getURLWithQueryString(
          'contents/articles',
          {
            filter:
              typeof tag === 'object'
                ? `tag+eq+${tag.join(`+${operator}+tag${operator === 'and' ? '2' : ''}+eq+`)}`
                : `tag+eq+${tag}`,
            limit: limit || 10,
            orderby: orderby || 'evergreen,-revision/updated'
          },
          { encode: false }
        ),
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
          dispatch(
            setArticles(
              typeof tag === 'object' ? `${tag.map(t => t).join('-')}` : `${tag}`,
              models.articles(data.articles, data.authors),
              paging
            )
          ),
        onError: () =>
          Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
      }));
}

// TODO: Las acciones son distintas pero el reducer no
export function getProductArticles(productId: string) {
  return (dispatch: Dispatch) => {
    dispatch(setArticlesFetchingStatus(productId, true));

    return finectAPI({
      version: 'v4',
      path: `contents/products/${productId}/articles`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
        dispatch(setArticles(productId, models.articles(data.articles, data.authors), paging)),
      onError: () =>
        Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos del producto' }))])
    });
  };
}

//* grupos enterprise gome  */

export function setEnterpriseAdvisers(enterprises: Array<Object>) {
  return {
    type: 'SET_ENTERPRISE_ADVISERS',
    enterprises
  };
}

export function getEnterpriseAdvisers() {
  return (dispatch: Dispatch, getState: Function) => {
    const enterprisesFetched = getState().content.enterpriseAdvisers.fetched;
    if (!enterprisesFetched) {
      finectAPI({
        version: 'v4',
        path: `contents/profiles/organizations?filter=features/enterprise+eq+true+and+tag+eq+34490&orderby=evergreen`,
        method: 'GET',
        onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setEnterpriseAdvisers(data)),
        onError: () =>
          Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
      });
    }
  };
}

export function setBanksFetchingStatus(fetching: boolean) {
  return {
    type: 'SET_BANKS_FETCHING_STATUS',
    fetching
  };
}

export function setBanks(banks: Array<Object>) {
  return {
    type: 'SET_BANKS',
    banks
  };
}

export function clearBanks() {
  return {
    type: 'CLEAR_BANKS'
  };
}

export function getBanks(tagId: number, limit?: number) {
  return function (dispatch: Dispatch) {
    dispatch(setBanksFetchingStatus(true));

    return finectAPI({
      version: 'v4',
      method: 'GET',
      path: getURLWithQueryString(
        'contents/profiles/publics/organizations',
        {
          filter: `tag+eq+${tagId}`,
          limit: limit || undefined,
          orderby: 'evergreen'
        },
        { encode: false }
      ),
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setBanks(data)),
      onError: () => dispatch(setBanksFetchingStatus(false))
    });
  };
}

/**
 * Acción que carga un artículo a partir del ID del autor y su identificador
 *
 * @param {string} profile ID del profile
 * @param {string} article ID (o alias) del artículo
 *
 */
export function getArticle(cookies: string, profile: string, article: string) {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/${profile ? `profiles/${profile}/` : ''}articles/${article}`,
      credentials: cookies,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => dispatch(setArticle(models.article(data.article, [data.author]))),
      onError: () => dispatch(setArticleNotFound())
    });
  };
}

export function fetchArticle(
  cookies: string = 'include',
  subtype: 'usuario' | 'grupos',
  profile: string,
  alias: string,
  withRecommendedArticles: boolean = true,
  withInfiniteScroll: boolean = false
) {
  return function (dispatch: Dispatch, getState: Function) {
    const promises = [];
    promises.push(dispatch(getArticle(cookies, profile, alias)));
    promises.push(dispatch(fetchSetting('articles-pill-advisers-leads')));

    if (subtype && profile) {
      // by default fetches 20 articles whereas we are only showing 5, but it may be better bc of the cache
      ['usuario'].includes(subtype) && promises.push(dispatch(getArticlesFeed('filter=tag+ne+-1')));
      ['grupos'].includes(subtype) && promises.push(dispatch(getRelatedGroupArticles(cookies, profile, 5)));
    }
    withRecommendedArticles && promises.push(dispatch(getRecommendedArticles(cookies, 25)));

    return Promise.all(promises).then(() => {
      const state = getState();
      const postPromises = [];

      if (state.content.article.model) {
        const { author = {} } = state.content.article.model;
        if (!subtype && !profile) {
          // by default fetches 20 articles whereas we are only showing 5, but it may be better bc of the cache
          ['user'].includes(author?.subtype) && promises.push(dispatch(getArticlesFeed('filter=tag+ne+-1')));
          ['company'].includes(author?.subtype) && promises.push(dispatch(getRelatedGroupArticles(cookies, author?.alias, 5)));
        }

        const showTagContent = !['company'].includes(author?.subtype) || aliasWithTagContent.includes(alias);
        if (showTagContent) {
          (state.content.article.model.socialStats?.tags || []).forEach((tag) => {
            postPromises.push(dispatch(fetchSetting(`articles-tag-${tag.id}`)));
          });
        }

        isBrowser() && postPromises.push(dispatch(getRelatedQuestions(state.content.article.model, cookies)));

        if (withInfiniteScroll) {
          if (['grupos'].includes(subtype)) {
            state.content.article.model?.author?.alias &&
              postPromises.push(dispatch(getTagNextArticles(state.content.article.model.author.alias, 10)));
          } else {
            // get a random tag from the article
            const tag =
              state.content.article.model?.socialStats?.tags[
                Math.floor(Math.random() * state.content.article.model.socialStats?.tags.length)
              ];
            tag?.id && postPromises.push(dispatch(getTagNextArticles(tag.id, 10)));
          }
        }
      }

      return Promise.all(postPromises);
    });
  };
}
